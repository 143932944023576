import { Provider } from 'react-redux';
import { useRouter as useNextRouter } from 'next/router';
import { useEffect } from 'react';
import Script from 'next/script';
import { setTranslations, setLanguage, getLanguage } from 'react-switch-lang';
import { config, library } from '@fortawesome/fontawesome-svg-core';
import '@fortawesome/fontawesome-svg-core/styles.css';
import { fal } from '@fortawesome/pro-light-svg-icons';

import Redirect from '../components/Redirect';
import NotFound from './404';
import { useRouter } from '../components/Router';
import { useStore } from '../redux/Store';
import { isProduction } from '../utils/HostingEnv';
import { mapPathArray } from '../utils/FrenchUrlMapping';
import 'bootstrap/dist/css/bootstrap.css';
import '../public/Canadiana/fonts.css';
import '../styles/Global.scss';
import '../styles/Footer.scss';
import '../styles/TextPage.scss';
import '../styles/Purchase.scss';
import '../styles/Card.scss';
import '../styles/Pace.scss';
import '../styles/Form.scss';
import '../styles/SkipToNav.scss';
import '../styles/Legal.scss';
import '../styles/SiteMap.scss';

import English from '../languages/English.json';
import French from '../languages/French.json';
import { events, logAmpEvent } from '../utils/Amplitude';

config.autoAddCss = false;
library.add(fal);

setTranslations({
  en: English,
  fr: French,
});

const pages = mapPathArray([
  '/cardholder-agreement',
  '/electronic-consent-agreement',
  '/slp-cardholder-agreement',
  '/home',
  '/link-card',
  '/login',
  '/terms-of-service-mobile',
  '/my-card',
  '/purchase',
  '/register',
  '/maintenance',
]);
const hiddenPages = mapPathArray([
  '/forgot-password',
  '/error',
  '/logout',
  '/site-map',
]).map((path) => `/[lang]${path}`);

function MyApp({ Component, pageProps }) {
  const store = useStore();
  const router = useNextRouter();
  const {
    query: { lang: langFromQuery },
    asPath: path,
    pathname,
  } = router;
  let lang = langFromQuery;

  if (pathname === '/404') lang = getLanguage();

  try {
    if (typeof localStorage === 'undefined') {
      lang = lang || 'en';
    } else if (lang) {
      // lang in query takes precedence over saved lang in localStorage
      localStorage.setItem('language', lang);
    } else {
      // if there's no lang passed in from the URL, try to retrieve saved lang
      lang = localStorage.getItem('language');

      // if there's no saved lang, save en
      if (lang !== 'en' && lang !== 'fr') {
        lang = 'en';
        localStorage.setItem('language', 'en');
      }
    }
  } catch (err) {
    // try-catch just here to prevent exception in Sentry
  }

  useEffect(() => {
    // Help Centre widget click tracking
    const logEvent = ({ data }) => {
      if (data === 'showWidget') logAmpEvent(events.USER_OPENED_HELP_CENTRE_WIDGET);
    };
    window.addEventListener('message', logEvent);
    return () => {
      window.removeEventListener('message', logEvent);
    };
  }, []);

  setLanguage(lang);

  return (
    <Provider store={store}>
      <AuthStateSync />
      {path.slice(1, 7) === '[lang]' && hiddenPages.some((page) => page.includes(path.slice(7))) &&
        <NotFound {...pageProps} lang={lang} />}
      {/* add lang to path if not specified */}
      {pages.includes(path) ? (
        <Redirect path={`/${lang}${path}`} asIs />
      ) : (
        <Component {...pageProps} lang={lang} />
      )}
      {isProduction && <Script src="https://www.googletagmanager.com/gtag/js?id=G-6972V37ECV" />}
    </Provider>
  );
}

// Component that listens to the auth state changes across the tabs.
function AuthStateSync() {
  const router = useRouter();

  useEffect(() => {
    function handleLogoutEvent({ key, newValue }) {
      const eventIdentifier = `CognitoIdentityServiceProvider.${process.env.NEXT_PUBLIC_COGNITO_CLIENT_ID}.LastAuthUser`;
      // When the user logs out, the cognito fires the event with newValue being null.
      if (key === eventIdentifier && newValue === null) {
        router.push({ pathname: '/logout', query: { reason: 'sync' } });
      }
    }
    window.addEventListener('storage', handleLogoutEvent);
    return () => window.removeEventListener('storage', handleLogoutEvent);
  }, [router]);

  return (<></>);
}

export default MyApp;
