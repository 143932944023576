import Amplitude from 'amplitude-js';
import { isDevelopment } from './HostingEnv';

const AMPLITUDE_SESSION_TIMEOUT = 20 * 60 * 1000;
const ampLogStyle = 'background-color:aqua; color:black;';

const logToConsole = (...args) => {
  if (isDevelopment) console.log(...args); // eslint-disable-line no-console
};

if (typeof window !== 'undefined') {
  Amplitude.getInstance().init(process.env.NEXT_PUBLIC_AMPLITUDE_KEY, null, {
    includeReferrer: true,
    includeUtm: true,
    includeGclid: true,
    sessionTimeout: AMPLITUDE_SESSION_TIMEOUT,
  });
  Amplitude.getInstance().setVersionName(process.env.NEXT_PUBLIC_VERSION);
}

export const getAmpSessionID = () => Amplitude.getInstance().getSessionId();
export const setAmpUser = (email) => {
  logToConsole(`%c[Amplitude]%c Setting user ID: ${email}`, ampLogStyle, '');
  Amplitude.getInstance().setUserId(email);
};
export const setUserProps = (properties) => {
  logToConsole('%c[Amplitude]%c Setting user property:', ampLogStyle, '', properties);
  Amplitude.getInstance().setUserProperties(properties);
};

export const logAmpEvent = (event, properties = undefined) => {
  logToConsole(
    `Amplitude event: %c${event}`,
    ampLogStyle,
    properties ? 'with properties:' : '',
    properties || ''
  );
  Amplitude.getInstance().logEvent(event, properties);
};

export const events = {
  // navbar
  USER_CLICKED_NAVBAR_LINK: 'USER_CLICKED_NAVBAR_LINK',

  // landing
  USER_VIEWED_MAIN_LANDING_PAGE: 'USER_VIEWED_MAIN_LANDING_PAGE',
  USER_CLICKED_CTA_HERO_LANDING: 'USER_CLICKED_CTA_HERO_LANDING',
  USER_CLICKED_REGISTER_LANDING: 'USER_CLICKED_REGISTER_LANDING',
  USER_CLICKED_LOGIN_LANDING: 'USER_CLICKED_LOGIN_LANDING',
  USER_CLICKED_CTA_BOTTOM_LANDING: 'USER_CLICKED_CTA_BOTTOM_LANDING',
  USER_CLICKED_LANDING_CTA_BUY_NOW: 'USER_CLICKED_LANDING_CTA_BUY_NOW',
  USER_CLICKED_LANDING_CTA_SIGN_IN: 'USER_CLICKED_LANDING_CTA_SIGN_IN',

  // footer
  USER_CLICKED_FOOTER_LINK: 'USER_CLICKED_FOOTER_LINK',
  USER_CHANGED_LANGUAGE: 'USER_CHANGED_LANGUAGE',

  // auth process
  USER_VIEWED_REGISTRATION_PAGE: 'USER_VIEWED_REGISTRATION_PAGE',
  USER_CLICKED_REGISTER_BUTTON: 'USER_CLICKED_REGISTER_BUTTON',
  USER_CLICKED_LOGIN_LINK_FROM_REGISTER: 'USER_CLICKED_LOGIN_LINK_FROM_REGISTER',
  USER_CLICKED_CHANGE_EMAIL_REGISTER: 'USER_CLICKED_CHANGE_EMAIL_REGISTER',
  USER_CLICKED_VERIFY_EMAIL_REGISTER: 'USER_CLICKED_VERIFY_EMAIL_REGISTER',

  USER_VIEWED_LOGIN_PAGE: 'USER_VIEWED_LOGIN_PAGE',
  USER_CLICKED_LOGIN_BUTTON: 'USER_CLICKED_LOGIN_BUTTON',
  USER_CLICKED_REGISTER_FROM_LOGIN: 'USER_CLICKED_REGISTER_FROM_LOGIN',
  USER_CLICKED_FORGOT_PASSWORD: 'USER_CLICKED_FORGOT_PASSWORD',

  USER_VIEWED_CONFIRM_EMAIL_PAGE: 'USER_VIEWED_CONFIRM_EMAIL_PAGE',

  USER_VIEWED_FORGOT_PASSWORD_PAGE: 'USER_VIEWED_FORGOT_PASSWORD_PAGE',
  USER_CLICKED_RESEND_CODE: 'USER_CLICKED_RESEND_CODE', // set Process event property as the auth step
  USER_CLICKED_SEND_CODE_FORGOTPASSWORD: 'USER_CLICKED_SEND_CODE_FORGOTPASSWORD',
  USER_CLICKED_UPDATE_PASSWORD: 'USER_CLICKED_UPDATE_PASSWORD',

  USER_CLICKED_GOOGLE_RECAPTCHA_PRIVACY_POLICY_LINK: 'USER_CLICKED_GOOGLE_RECAPTCHA_PRIVACY_POLICY_LINK',
  USER_CLICKED_GOOGLE_RECAPTCHA_TERMS_AND_CONDITIONS_LINK: 'USER_CLICKED_GOOGLE_RECAPTCHA_TERMS_AND_CONDITIONS_LINK',
  GOOGLE_RECAPTCHA_ERROR: 'GOOGLE_RECAPTCHA_ERROR',
  GOOGLE_RECAPTCHA_CANCEL: 'GOOGLE_RECAPTCHA_CANCEL',

  USER_CLICKED_MAILCHECK_SUGGESTION_NO_BUTTON: 'USER_CLICKED_MAILCHECK_SUGGESTION_NO_BUTTON',
  USER_CLICKED_MAILCHECK_SUGGESTION_YES_BUTTON: 'USER_CLICKED_MAILCHECK_SUGGESTION_YES_BUTTON',

  // purchase
  USER_VIEWED_BEGIN_SCREEN_PURCHASE: 'USER_VIEWED_BEGIN_SCREEN_PURCHASE',
  USER_OPENED_PROXY_LOCATION_HELP: 'USER_OPENED_PROXY_LOCATION_HELP',
  USER_CLOSED_PROXY_LOCATION_HELP: 'USER_CLOSED_PROXY_LOCATION_HELP',
  USER_VIEWED_SCREENING_QUESTION_PURCHASE: 'USER_VIEWED_SCREENING_QUESTION_PURCHASE',
  USER_VIEWED_DETAILS_SCREEN_PURCHASE: 'USER_VIEWED_DETAILS_SCREEN_PURCHASE',
  USER_VIEWED_REVIEW_SCREEN_PURCHASE: 'USER_VIEWED_REVIEW_SCREEN_PURCHASE',
  USER_VIEWED_PAYMENT_SCREEN_PURCHASE: 'USER_VIEWED_PAYMENT_SCREEN_PURCHASE',
  USER_VIEWED_ETRANSFER_CODES_SCREEN_PURCHASE: 'USER_VIEWED_ETRANSFER_CODES_SCREEN_PURCHASE',
  USER_VIEWED_PAYMENT_SENT_SCREEN_PURCHASE: 'USER_VIEWED_PAYMENT_SENT_SCREEN_PURCHASE',
  USER_CLICKED_SCREENING_ANSWER_BUTTON_PURCHASE: 'USER_CLICKED_SCREENING_ANSWER_BUTTON_PURCHASE',
  USER_VIEWED_SCREENING_FAILURE_MESSAGE_PURCHASE: 'USER_VIEWED_SCREENING_FAILURE_MESSAGE_PURCHASE',
  USER_CLICKED_START_APPLICATION_PURCHASE: 'USER_CLICKED_START_APPLICATION_PURCHASE',
  USER_STARTED_APPLICATION_PURCHASE: 'USER_STARTED_APPLICATION_PURCHASE',
  USER_CLICKED_EDIT_PURCHASE: 'USER_CLICKED_EDIT_PURCHASE',
  USER_CLICKED_RESTART_PURCHASE: 'USER_CLICKED_RESTART_PURCHASE',
  USER_CLICKED_CONTINUE_PURCHASE: 'USER_CLICKED_CONTINUE_PURCHASE',
  USER_CLICKED_BACK_PURCHASE: 'USER_CLICKED_BACK_PURCHASE',
  USER_CLICKED_REGISTER_PURCHASE: 'USER_CLICKED_REGISTER_PURCHASE',
  USER_CLICKED_LOGIN_PURCHASE: 'USER_CLICKED_LOGIN_PURCHASE',
  USER_VIEWED_IDENTIFICATION_SCREEN_PURCHASE: 'USER_VIEWED_IDENTIFICATION_SCREEN_PURCHASE',
  USER_CLICKED_START_PAYMENT_PURCHASE: 'USER_CLICKED_START_PAYMENT_PURCHASE',
  USER_CLICKED_ETRANSFER_CODE_COPY: 'USER_CLICKED_ETRANSFER_CODE_COPY',
  USER_CLICKED_SENT_ETRANSFER_PURCHASE: 'USER_CLICKED_SENT_ETRANSFER_PURCHASE',
  USER_CLICKED_RETURN_HOME_PURCHASE: 'USER_CLICKED_RETURN_HOME_PURCHASE',
  ONFIDO_ERROR: 'ONFIDO_ERROR',
  ONFIDO_IDENTIFICATION_SUCCESS: 'ONFIDO_IDENTIFICATION_SUCCESS',
  USER_OPENED_INTERAC_E_TRANSFER_HELPCENTRE_LINK: 'USER_OPENED_INTERAC_E_TRANSFER_HELPCENTRE_LINK',
  USER_OPENED_SPECIAL_BANKS_HELPCENTRE_LINK: 'USER_OPENED_SPECIAL_BANKS_HELPCENTRE_LINK',
  USER_OPENED_BILL_PAY_HELPCENTRE_LINK: 'USER_OPENED_BILL_PAY_HELPCENTRE_LINK',
  USER_OPENED_CREATE_OWN_QA_HELPCENTRE_LINK: 'USER_OPENED_CREATE_OWN_QA_HELPCENTRE_LINK',

  USER_VIEWED_NO_CARD_LINKED_PAGE: 'USER_VIEWED_NO_CARD_LINKED_PAGE',
  USER_VIEWED_LINK_CARD_PAGE: 'USER_VIEWED_LINK_CARD_PAGE',
  USER_CLICKED_LINK_CARD: 'USER_CLICKED_LINK_CARD',
  USER_CLICKED_PURCHASE_CARD_FROM_NO_LINKED_CARD: 'USER_CLICKED_PURCHASE_CARD_FROM_NO_LINKED_CARD',
  USER_CLICKED_LINK_CARD_FROM_NO_LINKED_CARD: 'USER_CLICKED_LINK_CARD_FROM_NO_LINKED_CARD',
  USER_CLICKED_LINK_CARD_FROM_PURCHASE_CARD: 'USER_CLICKED_LINK_CARD_FROM_PURCHASE_CARD',
  USER_CLICKED_CANCEL_LINK_CARD: 'USER_CLICKED_CANCEL_LINK_CARD',

  // my card
  USER_VIEWED_MY_CARD_HOME_PAGE: 'USER_VIEWED_MY_CARD_HOME_PAGE',
  USER_VIEWED_RELOAD_TERMS_AND_CONDITIONS_PAGE: 'USER_VIEWED_RELOAD_TERMS_AND_CONDITIONS_PAGE',
  USER_VIEWED_RELOAD_AMOUNT_PAGE: 'USER_VIEWED_RELOAD_AMOUNT_PAGE',
  USER_VIEWED_RELOAD_ETRANSFER_CODES_PAGE: 'USER_VIEWED_RELOAD_ETRANSFER_CODES_PAGE',
  USER_VIEWED_RELOAD_ETRANSFER_SENT_PAGE: 'USER_VIEWED_RELOAD_ETRANSFER_SENT_PAGE',
  USER_VIEWED_CARD_MANAGEMENT_PAGE: 'USER_VIEWED_CARD_MANAGEMENT_PAGE',
  USER_VIEWED_SETTINGS_PAGE: 'USER_VIEWED_SETTINGS_PAGE',
  USER_VIEWED_TRANSACTIONS_PAGE: 'USER_VIEWED_TRANSACTIONS_PAGE',
  USER_CLICKED_LEGAL_DROPDOWN: 'USER_CLICKED_LEGAL_DROPDOWN',
  USER_CLICKED_LEGAL_DOCUMENT_LINK: 'USER_CLICKED_LEGAL_DOCUMENT_LINK',
  USER_CLICKED_CANADA_POST_CONTACT_US_LINK: 'USER_CLICKED_CANADA_POST_CONTACT_US_LINK',
  USER_CLICKED_FEES_LIMITS_LINK: 'USER_CLICKED_FEES_LIMITS_LINK',
  USER_CLICKED_CARD_FEATURE_LINK: 'USER_CLICKED_CARD_FEATURE_LINK',
  USER_CLICKED_SIDEBAR_LOGOUT: 'USER_CLICKED_SIDEBAR_LOGOUT',
  USER_CLICKED_TRANSACTIONS_DATE_PICKER: 'USER_CLICKED_TRANSACTIONS_DATE_PICKER',
  USER_CLICKED_TRANSACTIONS_SEARCH: 'USER_CLICKED_TRANSACTIONS_SEARCH',
  USER_CLICKED_SIXMONTHS_SEARCH: 'USER_CLICKED_SIXMONTHS_SEARCH',
  USER_CLICKED_TRANSACTIONS_NEXT_PAGE: 'USER_CLICKED_TRANSACTIONS_NEXTPAGE',
  USER_CLICKED_TRANSACTIONS_PREVIOUS_PAGE: 'USER_CLICKED_TRANSACTIONS_PREVIOUSPAGE',
  USER_CLICKED_RELOAD_TERMS_AND_CONDITION_CONTINUE_BUTTON: 'USER_CLICKED_RELOAD_TERMS_AND_CONDITION_CONTINUE_BUTTON',
  USER_CLICKED_RELOAD_START_PAYMENT_BUTTON: 'USER_CLICKED_RELOAD_START_PAYMENT_BUTTON',
  USER_CLICKED_RELOAD_RETURN_HOME_BUTTON: 'USER_CLICKED_RELOAD_RETURN_HOME_BUTTON',
  USER_CLICKED_NEAREST_CANADAPOST_BUTTON: 'USER_CLICKED_NEAREST_CANADAPOST_BUTTON',
  USER_CLICKED_CARD_MANAGEMENT_FEATURE_BUTTON: 'USER_CLICKED_CARD_MANAGEMENT_FEATURE_BUTTON',
  USER_VIEWED_CARD_SUSPEND_UNSUSPEND_PAGE: 'USER_VIEWED_CARD_SUSPEND_UNSUSPEND_PAGE',
  USER_VIEWED_CARD_SUSPEND_UNSUSPEND_CONFIRM_PAGE: 'USER_VIEWED_CARD_SUSPEND_UNSUSPEND_CONFIRM_PAGE',
  USER_VIEWED_UNBLOCK_PIN_PAGE: 'USER_VIEWED_UNBLOCK_PIN_PAGE',
  USER_CLICKED_SUSPEND_CARD_BUTTON: 'USER_CLICKED_SUSPEND_CARD_BUTTON',
  USER_CLICKED_UNSUSPEND_CARD_BUTTON: 'USER_CLICKED_UNSUSPEND_CARD_BUTTON',
  USER_CLICKED_SUSPEND_CARD_CONFIRM_BUTTON: 'USER_CLICKED_SUSPEND_CARD_CONFIRM_BUTTON',
  USER_CLICKED_UNSUSPEND_CARD_CONFIRM_BUTTON: 'USER_CLICKED_UNSUSPEND_CARD_CONFIRM_BUTTON',
  USER_CLICKED_UNBLOCK_PIN_BUTTON: 'USER_CLICKED_UNBLOCK_PIN_BUTTON',
  USER_SUSPENDED_CARD: 'USER_SUSPENDED_CARD',
  USER_UNSUSPENDED_CARD: 'USER_UNSUSPENDED_CARD',
  USER_VIEWED_CARD_REPLACEMENT_REASON: 'USER_VIEWED_CARD_REPLACEMENT_REASON',
  USER_VIEWED_CARD_REPLACEMENT_TERMS: 'USER_VIEWED_CARD_REPLACEMENT_TERMS',
  USER_VIEWED_CARD_REPLACEMENT_CONFIRM: 'USER_VIEWED_CARD_REPLACEMENT_CONFIRM',
  USER_VIEWED_CARD_REPLACEMENT_SUCCESS: 'USER_VIEWED_CARD_REPLACEMENT_SUCCESS',
  USER_VIEWED_CARD_REPLACEMENT_INSUFFICIENT_FUNDS_ERROR: 'USER_VIEWED_CARD_REPLACEMENT_INSUFFICIENT_FUNDS_ERROR',
  USER_VIEWED_CARD_REPLACEMENT_INVALID_CARD_STATUS_ERROR: 'USER_VIEWED_CARD_REPLACEMENT_INVALID_CARD_STATUS_ERROR',
  USER_CLICKED_CARD_REPLACEMENT_CONTINUE: 'USER_CLICKED_CARD_REPLACEMENT_CONTINUE',
  USER_CLICKED_CARD_REPLACEMENT_RETURN_HOME: 'USER_CLICKED_CARD_REPLACEMENT_RETURN_HOME',
  USER_CLICKED_LINK_NEW_CARD_SETTINGS: 'USER_CLICKED_LINK_NEW_CARD_SETTINGS',
  USER_CLICKED_EDIT_PERSONAL_INFORMATION_SETTINGS: 'USER_CLICKED_EDIT_PERSONAL_INFORMATION_SETTINGS',
  USER_OPENED_TRANSACTIONS_DETAILS_DROPDOWN: 'USER_OPENED_TRANSACTIONS_DETAILS_DROPDOWN',
  USER_CLOSED_TRANSACTIONS_DETAILS_DROPDOWN: 'USER_CLOSED_TRANSACTIONS_DETAILS_DROPDOWN',
  USER_CLICKED_SAVE_TRANSACTIONS: 'USER_CLICKED_SAVE_TRANSACTIONS',
  USER_CLICKED_MY_CARD_BACK_BUTTON: 'USER_CLICKED_MY_CARD_BACK_BUTTON',
  USER_CLICKED_MY_CARD_CANCEL_BUTTON: 'USER_CLICKED_MY_CARD_CANCEL_BUTTON',

  // maintenance
  USER_VIEWED_MAINTENANCE_PAGE: 'USER_VIEWED_MAINTENANCE_PAGE',
  USER_CLICKED_MAINTENANCE_ANIMATION_BUTTON: 'USER_CLICKED_MAINTENANCE_ANIMATION_BUTTON',
  API_MAINTENANCE_MODE: 'API_MAINTENANCE_MODE',

  // direct deposit
  USER_VIEWED_DIRECT_DEPOSIT_TERMS_PAGE: 'USER_VIEWED_DIRECT_DEPOSIT_TERMS_PAGE',
  USER_VIEWED_DIRECT_DEPOSIT_CONFIRM_PAGE: 'USER_VIEWED_DIRECT_DEPOSIT_CONFIRM_PAGE',
  USER_VIEWED_DIRECT_DEPOSIT_ACCOUNT_INFO_PAGE: 'USER_VIEWED_DIRECT_DEPOSIT_ACCOUNT_INFO_PAGE',
  USER_CLICKED_DIRECT_DEPOSIT_COPY: 'USER_CLICKED_DIRECT_DEPOSIT_COPY',
  USER_CLICKED_DIRECT_DEPOSIT_PRINT_VOID_CHEQUE_BUTTON: 'USER_CLICKED_DIRECT_DEPOSIT_PRINT_VOID_CHEQUE_BUTTON',
  USER_CLICKED_DIRECT_DEPOSIT_DONE_BUTTON: 'USER_CLICKED_DIRECT_DEPOSIT_DONE_BUTTON',
  USER_CLICKED_DIRECT_DEPOSIT_AGREE_TO_TERMS_BUTTON: 'USER_CLICKED_DIRECT_DEPOSIT_AGREE_TO_TERMS_BUTTON',
  USER_VIEWED_DIRECT_DEPOSIT_BLOCKED_PAGE: 'USER_VIEWED_DIRECT_DEPOSIT_BLOCKED_PAGE',

  // personal infromation
  USER_VIEWED_PERSONAL_INFORMATION_PAGE: 'USER_VIEWED_PERSONAL_INFORMATION_PAGE',
  USER_CLICKED_UPDATE_PERSONAL_INFORMATION: 'USER_CLICKED_UPDATE_PERSONAL_INFORMATION',
  USER_CLICKED_CONFIRM_INFORMATION_EDIT_BUTTON: 'USER_CLICKED_CONFIRM_INFORMATION_EDIT_BUTTON',
  USER_CLICKED_CONFIRM_INFORMATION_CONFIRM_BUTTON: 'USER_CLICKED_CONFIRM_INFORMATION_CONFIRM_BUTTON',

  // general
  API_ERROR: 'API_ERROR',
  API_SUCCESS: 'API_SUCCESS',
  USER_VIEWED_ERROR_PAGE: 'USER_VIEWED_ERROR_PAGE',
  USER_CLICKED_ERROR_PAGE_BUTTON: 'USER_CLICKED_ERROR_PAGE_BUTTON',
  USER_CLICKED_DOWNLOAD_APP: 'USER_CLICKED_DOWNLOAD_APP',
  USER_ENTERED_INVALID_VALUE: 'USER_ENTERED_INVALID_VALUE',
  USER_CLICKED_PRINT_BUTTON: 'USER_CLICKED_PRINT_BUTTON',
  USER_VIEWED_LEGAL_DOC_PAGE: 'USER_VIEWED_LEGAL_DOC_PAGE',
  USER_OPENED_HELP_CENTRE_WIDGET: 'USER_OPENED_HELP_CENTRE_WIDGET',
  USER_LOGGED_OUT: 'USER_LOGGED_OUT',
};
